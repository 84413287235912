<template>
  <div v-if="isAuthenticated" class="page-wrapper">
    <Aside />
    <Header />
    <div
      class="
        container
        d-flex
        flex-column
        align-items-center
        justify-content-center
        w-100
        h-100
      "
    >
      <h1 class="text-secondary">
        <strong>404</strong>
      </h1>
      <h3>Nie znaleziono strony</h3>
      <router-link
        to="/dashboard"
        class="btn btn-secondary btn-lg mt-5"
        style="line-height: 40px"
        >Powrót do strony głównej</router-link
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Aside from "@/view/layout/aside/Aside";
import Header from "@/view/layout/header/Header";

export default {
  name: "NotFound",
  components: { Header, Aside },
  computed: {
    ...mapGetters(["isAuthenticated", "breadcrumbs", "pageTitle"]),
  },
};
</script>
